<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">所属通知：</span>
					<el-select v-model="searchtype" filterable placeholder="请选择" style="width: 2rem;" @change="search">
						<el-option v-for="item in options" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="warning" @click="tuihui">退回</el-button>
					<el-button type="success" @click="tongg">初审通过</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:showselect="true" @selectdata="selectdata" :czwidth="400" @getdata="gettable"
						@changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">通知详情</el-button>
							<el-button @click="gaojiandetile(scope.row)" type="primary" size="mini">稿件详情</el-button>
							<el-button @click="edite(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button v-show="scope.row.tui==2" @click="lookliyou(scope.row)" type="danger"
								size="mini">退回理由</el-button>
							<el-button @click="download(scope.row)" type="success" size="mini">下载</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>




		<!--审核弹出-->
		<el-dialog :title="ispass?'确认通过':'确认退回'" :visible.sync="showtc" width="40%">
			<div>
				<div style="height: 45vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="selectdatalist" :tableHeader="tableHeader2" :showpages="false"
						:showcz="false">
					</MyTable>
				</div>
				
				<el-form v-if="!ispass" ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="退回理由" required>
							<el-input
							  type="textarea"
							  :rows="4"
							  placeholder="请输入退回理由"
							  v-model="liyou">
							</el-input>
					  </el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showtc = false">取 消</el-button>
				<el-button :type="ispass?'success':'warning'" @click="queren">{{ispass?'通 过':'退 回'}}</el-button>
			</span>
		</el-dialog>



		<!--通知详情-->
		<tz_detail ref="tz_detail"></tz_detail>
		<!--稿件详情-->
		<content_detail ref="mycontent"></content_detail>
	</div>
</template>

<script>
	import tz_detail from '../components/tz_detail.vue';
	import content_detail from '../components/content_detail.vue';
	export default {
		components: {
			tz_detail,
			content_detail
		},
		data() {
			return {
				showzf: false,
				searchinput: '',
				searchtype: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '发稿标题',
						width: ''
					},
					{
						prop: 'huibaoren',
						name: '汇报人',
						width: '200'
					},
					{
						prop: 'addtime',
						name: '发稿时间',
						width: '200'
					},
					{
						prop: 'mystatus',
						name: '状态',
						width: '120',
						type: 'fagao_status'
					}
				],
				tableData: [],
				now_czid: '',
				user_id: '',
				user_name: '',
				options: [],
				selectdatalist: [],
				tableHeader2: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '发稿标题',
						width: ''
					},
					{
						prop: 'huibaoren',
						name: '汇报人',
						width: '120'
					},
					{
						prop: 'addtime',
						name: '发稿时间',
						width: '150'
					}
				],
				ispass:true,//是否为通过
				showtc:false,//显示确认弹窗
				liyou:''
			}
		},
		mounted() {
			this.$post({
				url: '/api/topics/index',
				params: {
					p: 1,
					size: 999
				}
			}).then((res) => {
				this.options = res.list
				this.searchtype = this.options[0].id
				this.gettable()
			})
		},
		methods: {
			download(row){
				this.$post({
					url: '/api/topicstg/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					let obj = {
						name: res.name,
						content: res.content,
						addtime: res.addtime,
						hbr: res.hbuser_name
					}
					this.$refs.mycontent.info = obj
					this.$refs.mycontent.isdis = true
					this.$refs.mycontent.dialogVisible = true
					this.$nextTick(()=>{
						this.$refs.mycontent.exportToWord()
					})
				})
			},
			lookliyou(row) {
				this.$confirm(row.liyou, '退回理由', {
						distinguishCancelAndClose: true,
						confirmButtonText: '修改并再次提交',
						cancelButtonText: '放弃修改'
					})
					.then(() => {
						this.$router.push({
							path: '/system/talk_fg_edit',
							query: {
								id: row.id
							}
						})
					})
					.catch(action => {
						// this.$message({
						// 	type: 'info',
						// 	message: action === 'cancel' ?
						// 		'放弃保存并离开页面' :
						// 		'停留在当前页面'
						// })
					});
			},
			queren(){
				let ids = this.selectdatalist.map(item =>{ return item.id})
				let obj ={
					id:this.searchtype,//通知id
					ids:ids.join(',')
				}
				if(!this.liyou&&!this.ispass){
					this.$message.warning('请输入退回理由')
					return
				}
				if(!this.ispass){
					obj.liyou = this.liyou
				}
				this.$post({
					url: this.ispass?'/api/topicstg/pltjedit':'/api/topicstg/plthedit',
					params: obj
				}).then((res) => {
					this.$message.success('操作成功')
					this.selectdatalist = []
					this.gettable()
					this.showtc = false
				})
			},
			tuihui() {
				if (this.selectdatalist.length <= 0) {
					this.$message.warning('请先选择数据')
					return
				}
				this.ispass = false
				this.showtc = true
			},
			tongg() {
				if (this.selectdatalist.length <= 0) {
					this.$message.warning('请先选择数据')
					return
				}
				var idExists = this.selectdatalist.some(obj => obj.tui == 2);
				if (idExists) {
					this.$confirm('所选数据包含已退回的，请重新选择', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.selectdatalist = []
						this.gettable()
					}).catch(() => {
						
					})
					return
				}
				this.ispass = true
				this.showtc = true
			},
			selectdata(e) {
				if (this.selectdatalist.length <= 0) {
					this.selectdatalist.push(e)
					return
				}
				if (this.selectdatalist.find(obj => obj.id === e.id)) { //判断是否包含该数据
					this.selectdatalist = this.selectdatalist.filter(obj => obj.id !== e.id); //包含就去掉
				} else {
					this.selectdatalist.push(e) //不包含推入数据
				}
			},
			edite(row) {
				this.$router.push({
					path: '/system/talk_fg_edit',
					query: {
						id: row.id
					}
				})
			},
			showeidt(row) {
				this.$post({
					url: '/api/topicstz/details',
					params: {
						id: row.topicstz_id
					}
				}).then((res) => {
					let obj = {
						name: res.topics_name,
						content: res.content,
						endtime: res.endtime,
						fujian: res.fujian,
						user_name: res.user_name
					}
					this.$refs.tz_detail.info = obj
					this.$refs.tz_detail.dialogVisible = true
				})

			},
			gaojiandetile(row) {
				this.$post({
					url: '/api/topicstg/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					let obj = {
						name: res.name,
						content: res.content,
						addtime: res.addtime,
						hbr: res.hbuser_name
					}
					this.$refs.mycontent.info = obj
					this.$refs.mycontent.dialogVisible = true
				})
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/topicstg/index',
					params: {
						topics_id: this.searchtype,
						ding: 2,
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					//ding--tui 两个字段判断状态
					// 1-1 待筛选。1-2 筛选退回。2-1.待初审 2-2 初审退回。3-1待复审 3-2复审退回。4-1 待定稿 4-2定稿退回 。5-1 定稿通过  5-2不存在这种情况
					//转为自定义状态
					//mystatus 对应的状态 1.待筛选  2.筛选退回 3.待初审  4.初审退回  5.待复审 6.复审退回  7.待定稿 8.定稿退回 9.定稿通过  66待提交
					res.list.forEach((item, index) => {						
						//修改汇报人
						item.huibaoren = item.user_id==item.hbuser_id?item.hbuser_name:item.hbuser_name+' 代表 '+item.user_name
						console.log(item.huibaoren)
						if (item.state == 1) {
							item.mystatus = 66
							return
						}
						if (item.ding == 1 && item.tui == 1) {
							item.mystatus = 1
						}
						if (item.ding == 1 && item.tui == 2) {
							item.mystatus = 2
						}
						if (item.ding == 2 && item.tui == 1) {
							item.mystatus = 3
						}
						if (item.ding == 2 && item.tui == 2) {
							item.mystatus = 4
						}
						if (item.ding == 3 && item.tui == 1) {
							item.mystatus = 5
						}
						if (item.ding == 3 && item.tui == 2) {
							item.mystatus = 6
						}
						if (item.ding == 4 && item.tui == 1) {
							item.mystatus = 7
						}
						if (item.ding == 4 && item.tui == 2) {
							item.mystatus = 8
						}
						if (item.ding == 5 && item.tui == 1) {
							item.mystatus = 9
						}
						if (item.ding == 6) {
							item.mystatus = 10
						}
						
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/talk_list_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'talk_chushen.scss';
</style>